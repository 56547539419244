import React, { useState } from "react";
import { Dialog, Alert } from "../../../components";
import {
  getInternalParams,
  createInternalOperation,
} from "../../api/internalMovements";
import {
  uiLanguage,
  userClientDb,
  userDepartmentId,
} from "../../auth/selectors/userSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getErrorMessage,
  getWarningMessage,
} from "../../../components/form/formUtils";
import { Redirect } from "react-router-dom";
import * as routes from "../../../app/routes";
import { Grid } from "@mui/material";
import { Form } from "react-final-form";
import {
  OutlinedSelectField,
  OutlinedTextField,
  OutlinedAutocomplete,
} from "../../../components/form";
import { required } from "../../../utils/validate";
import { useLoader } from "../../../hooks";

interface IInternalMovementsCreate {
  open: boolean;
  onClose: (event?: Record<string, unknown>) => void;
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

let id = "";

function InternalMovementsCreate(props: IInternalMovementsCreate) {
  const { open, onClose } = props;
  const [departmentOptions1, setDepartmentOptions1] =
    useState<Array<{ value: string; label: string }>>();
  const [departmentOptions2, setDepartmentOptions2] =
    useState<Array<{ value: string; label: string }>>();
  const [descriptionOptions, setDescriptionOptions] =
    useState<Array<{ value: string; label: string }>>();
  const [documentTypeOptions, setDocumentTypeOptions] =
    useState<Array<{ value: string; label: string }>>();
  const [redirect, setRedirect] = useState({ redirect: false, id: "" });
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    error: false,
  });
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const departmentId = useSelector(userDepartmentId);
  const [, setLoader] = useLoader();

  const params = {
    clientDbId: userDb ? userDb.id : 0,
    locale: locale,
    departmentId: departmentId,
  };

  const documentTypes = [
    { value: "1", label: translate("invoice", "Važtaraštis") },
    { value: "2", label: translate("order", "Užsakymas") },
    { value: "3", label: translate("offer", "Pasiūlymas") },
  ];

  const initialValues = React.useMemo(
    () => ({
      documentType:
        documentTypeOptions && documentTypeOptions[0]
          ? documentTypeOptions[0].value
          : "",
      departmentCode1: "",
      departmentCode2: "",
      descriptionCode1: "",
    }),
    [documentTypeOptions]
  );

  React.useEffect(
    () => {
      setLoader(true);
      getInternalParams(params)
        .then(({ data }) => {
          setLoader(false);
          if (data && data.length > 0) {
            // Departments senders list
            const opArray1 = data
              .filter(
                (operation: any) =>
                  operation.parameter.toLowerCase() === "department1"
              )
              .map((operation: any) => ({
                label: operation.code + ": " + operation.name,
                value:
                  typeof operation.code !== "string"
                    ? JSON.stringify(operation.code)
                    : operation.code,
              }));

            opArray1.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDepartmentOptions1(opArray1);

            // Departments recipients list
            const opArray2 = data
              .filter(
                (operation: any) =>
                  operation.parameter.toLowerCase() === "department2"
              )
              .map((operation: any) => ({
                label: operation.code + ": " + operation.name,
                value:
                  typeof operation.code !== "string"
                    ? JSON.stringify(operation.code)
                    : operation.code,
              }));

            opArray1.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDepartmentOptions2(opArray2);

            // Description options
            const opArray3 = data
              .filter(
                (operation: any) =>
                  operation.parameter.toLowerCase() === "description"
              )
              .map((operation: any) => ({
                label:
                  typeof operation.code !== "string"
                    ? JSON.stringify(operation.code)
                    : operation.code,
                value:
                  typeof operation.code !== "string"
                    ? JSON.stringify(operation.code)
                    : operation.code,
              }));

            opArray3.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDescriptionOptions(opArray3);

            // Documents types list
            const opArray4 = data.filter(
              (operation: any) =>
                operation.parameter.toLowerCase() === "doctypes"
            );

            const docArray = documentTypes.filter((type: any) => {
              return opArray4.some((el: any) => {
                return (
                  (typeof el.code !== "string"
                    ? JSON.stringify(el.code)
                    : el.code) === type.value
                );
              });
            });

            docArray.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDocumentTypeOptions(docArray);
          }
        })
        .catch((reason) => {
          setLoader(false);
          const message = getErrorMessage(reason);
          setShowAlert({ show: true, message: message.message, error: true });
        });
    }, // eslint-disable-next-line
    []
  );

  const handleErrorClose = () => {
    setShowAlert({ show: false, message: "", error: false });
    onClose();
  };

  const handleSubmit = (values: any) => {
    const opParams = {
      ...params,
      departmentCode1: values.departmentCode1,
      departmentCode2: values.departmentCode2,
      description: values.description,
      documentType: values.documentType,
    };

    createInternalOperation(opParams)
      .then(({ data, warnings }) => {
        if (warnings && warnings.length > 0) {
          id = warnings[0].operationCode;
          const message = getWarningMessage(warnings);
          setShowAlert({ show: true, message: message, error: false });
          return;
        }

        id = data[0].operationCode;
        setRedirect({ redirect: true, id: id });
      })
      .catch((reason) => {
        const message = getErrorMessage(reason);
        setShowAlert({ show: true, message: message.message, error: true });
      });
  };

  const handleWarningClose = React.useCallback(() => {
    setRedirect({ redirect: true, id: id });
  }, [setRedirect, id]);

  const showError = (
    <Alert
      isOpen={showAlert.show}
      message={showAlert.message}
      onClose={showAlert.error ? handleErrorClose : handleWarningClose}
      iconColor={showAlert.error ? "error" : "warning"}
    />
  );

  return (
    <div>
      {showAlert.show && showError}
      {redirect.redirect ? (
        <Redirect to={routes.ROUTE_INTERNAL_DETAILS.path + "/" + redirect.id} />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          subscription={subscription}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} method="post">
              <Dialog
                open={open}
                onClose={onClose}
                title={translate("createOperation", "Nauja operacija")}
                isConfirmationButton
                onConfirm={handleSubmit}
              >
                <Grid container spacing={1} direction="column">
                  <Grid item xs={12}>
                    <OutlinedSelectField
                      name="documentType"
                      fullWidth
                      label={translate("docType", "Dokumento tipas")}
                      options={documentTypeOptions}
                      otherColor="grey"
                      validate={required}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OutlinedSelectField
                      name="departmentCode1"
                      fullWidth
                      label={translate(
                        "departmentSender",
                        "Padalinys siuntėjas"
                      )}
                      options={departmentOptions1}
                      otherColor="grey"
                      validate={required}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OutlinedSelectField
                      name="departmentCode2"
                      fullWidth
                      label={translate(
                        "departmentRecipient",
                        "Padalinys gavėjas"
                      )}
                      options={departmentOptions2}
                      otherColor="grey"
                      validate={required}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {descriptionOptions && descriptionOptions.length > 0 ? (
                      <OutlinedAutocomplete
                        name="description"
                        fullWidth
                        label={translate("remarks", "Pastabos")}
                        options={descriptionOptions}
                        otherColor="grey"
                        renderType="renderSimple"
                      />
                    ) : (
                      <OutlinedTextField
                        name="description"
                        fullWidth
                        label={translate("remarks", "Pastabos")}
                        otherColor="grey"
                        autoComplete="off"
                        maxLength={60}
                      />
                    )}
                  </Grid>
                </Grid>
              </Dialog>
            </form>
          )}
        />
      )}
    </div>
  );
}

export default InternalMovementsCreate;
