import React, { useState } from "react";
import { Dialog, Alert } from "../../../components";
import { getScannerOpName, createScannerOperation } from "../../api/scanner";
import { uiLanguage, userClientDb } from "../../auth/selectors/userSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getErrorMessage,
  getWarningMessage,
} from "../../../components/form/formUtils";
import { Redirect } from "react-router-dom";
import * as routes from "../../../app/routes";
import { Form } from "react-final-form";
import {
  OutlinedTextField,
  OutlinedAutocomplete,
} from "../../../components/form";

interface IScannerFormCreate {
  open: boolean;
  onClose: () => void;
  onConfirm?: (value: string) => void;
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

let id = "";

function ScannerFormCreate(props: IScannerFormCreate) {
  const { open, onClose } = props;
  const [names, setNames] =
    useState<Array<{ value: number | string; label: string }>>();
  const [redirect, setRedirect] = useState({ redirect: false, id: "" });
  const [chosenValue, setChosenValue] = useState<{
    value: number | string;
    label: string;
  }>();
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    error: false,
  });
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const params = {
    clientDbId: userDb ? userDb.id : 0,
    locale: locale,
    name: "",
  };

  const initialValues = React.useMemo(
    () => ({
      documentNr: "",
      name: chosenValue && chosenValue.value ? chosenValue.value : "",
    }),
    [chosenValue]
  );

  React.useEffect(
    () => {
      getScannerOpName(params)
        .then(({ data }) => {
          if (data && data[0] && data[0].name) {
            const options = data[0].name;
            const opArray = options.split(/,\s*/).map((option: any) => {
              return { label: option, value: option };
            });
            setNames(opArray);

            if (opArray.length >= 1) {
              setChosenValue(opArray[0]);
            }
          }
        })
        .catch((reason) => {
          const message = getErrorMessage(reason);
          setShowAlert({ show: true, message: message.message, error: true });
        });
    },
    // eslint-disable-next-line
    []
  );

  const handleErrorClose = () => {
    setShowAlert({ show: false, message: "", error: false });
    onClose();
  };

  const handleConfirm = (values: any) => {
    const opParams = {
      ...params,
      ...values,
    };

    createScannerOperation(opParams)
      .then(({ data, warnings }) => {
        if (warnings && warnings.length > 0) {
          id = warnings[0].operationCode;
          const message = getWarningMessage(warnings);
          setShowAlert({ show: true, message: message, error: false });
          return;
        }

        id = data[0].operationCode;
        setRedirect({ redirect: true, id: id });
      })
      .catch((reason) => {
        const message = getErrorMessage(reason);
        setShowAlert({ show: true, message: message.message, error: true });
      });
  };

  const handleWarningClose = React.useCallback(() => {
    setRedirect({ redirect: true, id: id });
  }, [setRedirect, id]);

  const showError = (
    <Alert
      isOpen={showAlert.show}
      message={showAlert.message}
      onClose={showAlert.error ? handleErrorClose : handleWarningClose}
      iconColor={showAlert.error ? "error" : "warning"}
    />
  );

  return (
    <div>
      {showAlert.show && showError}
      {redirect.redirect ? (
        <Redirect to={routes.ROUTE_SCANNER_DETAILS.path + "/" + redirect.id} />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleConfirm}
          subscription={subscription}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} method="post">
              <Dialog
                open={open}
                onClose={onClose}
                title={translate("createOperation", "Nauja operacija")}
                isConfirmationButton
                onConfirm={handleSubmit}
              >
                {names && names.length > 0 && chosenValue ? (
                  <OutlinedAutocomplete
                    name="name"
                    fullWidth
                    label={translate("description", "Operacijos aprašymas")}
                    otherColor="grey"
                    options={names}
                    renderType="renderSimple"
                    defaultValue={chosenValue}
                  />
                ) : (
                  <OutlinedTextField
                    name="name"
                    fullWidth
                    label={translate("description", "Operacijos aprašymas")}
                    otherColor="grey"
                    autoComplete="off"
                  />
                )}
              </Dialog>
            </form>
          )}
        />
      )}
    </div>
  );
}

export default ScannerFormCreate;
