import React from "react";
import { Redirect, Route } from "react-router-dom";

interface IPrivateRouteProps {
  component: any;
  isAllowed: boolean;
  redirectTo: string;
  path: string;
}

function PrivateRoute(props: IPrivateRouteProps) {
  const {
    component: Component,
    isAllowed,
    redirectTo,
    path,
    ...componentProps
  } = props;

  return (
    <div>
      {isAllowed ? (
        <Route path={path}>
          <Component {...componentProps} />
        </Route>
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: path, redirect: true },
          }}
        />
      )}
    </div>
  );
}

export default PrivateRoute;
