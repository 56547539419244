import React, { useEffect, useState } from "react";
import { getModuleParams } from "../../api/parameters";
import {
  userId,
  userClientDb,
  uiLanguage,
  userDepartmentId,
} from "../../auth/selectors/userSelector";
import { useDispatch, useSelector } from "react-redux";
import { setUserDepartmentId } from "../../auth/slices/userSlice";
import {
  useLocalStorage,
  useAuthorizationError,
  useLoader,
} from "../../../hooks";
import { Select, Alert } from "../../../components";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "../../../components/form/formUtils";
import { usePreviousState } from "../../../hooks";

function DepartmentList() {
  const [translate] = useTranslation();
  const user = useSelector(userId);
  const userDb = useSelector(userClientDb);
  const userDepartment = useSelector(userDepartmentId);
  const [logoutUser] = useAuthorizationError();
  const dispatch = useDispatch();
  const [data, setData] = useState<Array<{ value: string; label: string }>>();
  const [localStorage, setLocalStorage] = useLocalStorage("departmentId");
  const [error, setError] = useState({ error: false, message: "", status: 0 });
  const [, setLoader] = useLoader();
  const locale = useSelector(uiLanguage);
  const previousDb = usePreviousState(userDb ? userDb.id : 0);

  const selectedDep = React.useMemo(() => {
    let dep = "";
    const chosen = data ? data.filter((dp) => dp.value === userDepartment) : [];

    if (chosen.length > 0) dep = chosen[0].value;
    return dep;
  }, [data, userDepartment]);

  const params = {
    clientDbId: userDb ? userDb.id : 0,
    locale: locale,
    module: "",
  };

  const assignDepId = React.useCallback(
    (selectedValue) => {
      const isUser =
        localStorage && Array.isArray(localStorage)
          ? localStorage.filter(
              (person: any) =>
                person.userId === user && userDb && person.dbId === userDb.id
            )
          : [];

      if (isUser.length === 0) {
        if (localStorage && localStorage.length > 0) {
          const newStorage = [
            ...localStorage,
            { departmentId: selectedValue, userId: user, dbId: userDb?.id },
          ];
          setLocalStorage(newStorage);
        } else {
          setLocalStorage([
            { departmentId: selectedValue, userId: user, dbId: userDb?.id },
          ]);
        }

        dispatch(setUserDepartmentId(selectedValue));
      } else {
        dispatch(setUserDepartmentId(selectedValue));

        const newStorage = localStorage.filter(
          (person: any) =>
            !(person.userId === user && person.dbId === userDb?.id)
        );
        setLocalStorage([
          ...newStorage,
          { departmentId: selectedValue, userId: user, dbId: userDb?.id },
        ]);
      }
    }, // eslint-disable-next-line
    [dispatch, data, setLocalStorage, userDb]
  );

  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    assignDepId(selectedValue);
  };

  useEffect(
    () => {
      if (user && userDb && previousDb !== userDb.id) {
        setLoader(true);

        getModuleParams(params)
          .then(({ data }) => {
            setLoader(false);
            const departmentsArray = data.filter(
              (dep: any) =>
                dep.parameter && dep.parameter.toUpperCase() === "DEPARTMENTS"
            );

            if (departmentsArray.length === 0) {
              assignDepId("");
              setData([]);
            }

            if (departmentsArray && departmentsArray.length > 0) {
              departmentsArray.unshift({
                code: "",
                name: translate("(none)"),
              });
              setData(
                departmentsArray.map((dep: any) => ({
                  value: "" + dep.code,
                  label: "" + dep.name,
                }))
              );

              let depId = "";
              const isUser =
                localStorage && Array.isArray(localStorage)
                  ? localStorage.filter(
                      (person: any) =>
                        person.userId === user &&
                        userDb &&
                        person.dbId === userDb.id
                    )
                  : [];

              if (isUser.length > 0) depId = isUser[0].departmentId;
              assignDepId(depId);
            }
          })
          .catch((reason) => {
            setLoader(false);
            const errMessage = getErrorMessage(reason);
            setError({
              error: true,
              message: errMessage.message,
              status: errMessage.status,
            });
          });
      }
    }, // eslint-disable-next-line
    [userDb, translate]
  );

  const handleClose = () => {
    logoutUser(error.status);

    if (error.status !== 401)
      setError({ error: false, message: "", status: 0 });
  };

  const showError = (
    <Alert
      isOpen={error.error}
      message={error.message}
      iconColor="error"
      onClose={handleClose}
    />
  );

  return (
    <div>
      {error.error && showError}
      <Select
        options={data}
        onChange={onClick}
        value={selectedDep}
        label={translate("departmentcode", "Padalinys")}
        fullWidth={true}
        disabled={!data || data.length === 0}
      />
    </div>
  );
}

export default DepartmentList;
