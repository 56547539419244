import React, { useEffect, useState } from "react";
import * as routes from "../../../app/routes";
import { HeaderField, InfiniteScrollList } from "../../../components/table";
import { useSelector } from "react-redux";
import {
  userClientDb,
  uiLanguage,
  userDepartmentId,
} from "../../auth/selectors/userSelector";
import { getDocHeaderById } from "../../api/docListsandDetails";
import {
  canUserModifyQuantities,
  getHeaderAdjustmentParams,
} from "../../api/parameters";
import {
  completeTheOperation,
  checkDetails,
  runClientsProcedure,
} from "../../api/completion";
import { Button, Alert, TextField, Label } from "../../../components";
import { useTranslation } from "react-i18next";
import { PriorityHigh } from "@mui/icons-material";
import { useParams, Redirect } from "react-router-dom";
import { useCompletionStyles } from "../css";
import {
  getErrorMessage,
  getWarningMessage,
} from "../../../components/form/formUtils";
import { useAuthorizationError, useLoader } from "../../../hooks";
import * as modules from "../../../utils/modules";
import { moduleType } from "../../../utils/modules";
import { Autocomplete } from "@mui/material";

function CompletionDetails() {
  const parameters = useParams<{ id: string; module: moduleType }>();
  const id = parameters.id;
  const module = parameters.module;
  const [logoutUser] = useAuthorizationError();
  const { classes } = useCompletionStyles();
  const [redirect, setRedirect] = useState(false);
  const [showOk, setShowOk] = useState(false);
  const [note, setNote] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [noteList, setNoteList] =
    useState<Array<{ value: number; label: string }>>();
  const [showAlert, setShowAlert] = useState<{
    show: boolean;
    message: string;
    status: number;
    iconColor: "success" | "error" | "warning";
    closeAfterError?: boolean;
  }>({ show: false, message: "", status: 0, iconColor: "warning" });
  const userDb = useSelector(userClientDb);
  const locale = useSelector(uiLanguage);
  const departmentId = useSelector(userDepartmentId);
  const userDbId = userDb ? userDb.id : 0;
  const [translate] = useTranslation();
  const [data, setData] = useState([]);
  const [, setLoader] = useLoader();
  const [headerData, setHeaderData] = useState({
    operationCode: "",
    operationType: "",
  });

  const [canUserCompleteWithErr, setCanUserCompleteWithErr] = useState(false);
  const [canUserModify, setCanUserModify] = useState(false);

  const headerParams = {
    clientDbId: userDbId,
    operationCode: id,
    module: module,
    locale: locale,
  };

  const loadDataparams = React.useMemo<{
    cellType: "header" | "info" | "item";
  }>(
    () => ({
      cellType: "item",
      complete: true,
    }),
    []
  );

  const link = React.useMemo(() => {
    let linkTo = { details: "/", list: "/" };

    switch (module) {
      case modules.MODULE_SALES:
        linkTo = {
          details: routes.ROUTE_SALES_DETAILS.path + `/${id}`,
          list: routes.ROUTE_SALES.path,
        };
        break;
      case modules.MODULE_PURCHASES:
        linkTo = {
          details: routes.ROUTE_PURCHASES_DETAILS.path + `/${id}`,
          list: routes.ROUTE_PURCHASES.path,
        };
        break;
      case modules.MODULE_INTERNAL:
        linkTo = {
          details: routes.ROUTE_INTERNAL_DETAILS.path + `/${id}`,
          list: routes.ROUTE_INTERNAL.path,
        };
        break;

      case modules.MODULE_SCANNER:
        linkTo = {
          details: routes.ROUTE_SCANNER_DETAILS.path + `/${id}`,
          list: routes.ROUTE_SCANNER.path,
        };
        break;

      case modules.MODULE_INVENTORY:
        linkTo = {
          details: routes.ROUTE_INVENTORY_DETAILS.path + `/${id}`,
          list: routes.ROUTE_INVENTORY.path,
        };
        break;

      case modules.MODULE_ASSEMBLY:
        linkTo = {
          details: routes.ROUTE_ASSEMBLY_DETAILS.path + `/${id}`,
          list: routes.ROUTE_ASSEMBLY.path,
        };
        break;
    }

    return linkTo;
  }, [module, id]);

  const check = React.useCallback(
    (completionParams) => {
      checkDetails(completionParams)
        .then((result) => {
          setLoader(false);
          if (result.warnings.length > 0) {
            setData(result.warnings);
            setShowWarning(true);
          } else {
            setShowOk(true);
          }
        })
        .catch((reason) => {
          setLoader(false);
          const message = getErrorMessage(reason);
          setShowAlert({
            show: true,
            message: message.message,
            status: message.status,
            iconColor: "error",
          });
        });
    }, // eslint-disable-next-line
    []
  );

  const finalize = React.useCallback(
    async (completionParams) => {
      setLoader(true);

      let isClientProcedure = false;
      let customProcedure: any = 0;
      let customWarnings: any[] = [];

      try {
        const { data, warnings } = await completeTheOperation(completionParams);

        if (warnings.length > 0 && warnings[0].customProcedure) {
          customProcedure = warnings[0].customProcedure;
        } else if (data.length > 0 && data[0].customProcedure) {
          customProcedure = data[0].customProcedure;
        }

        if (
          (typeof customProcedure === "string" && customProcedure === "1") ||
          customProcedure === 1
        )
          isClientProcedure = true;

        try {
          if (isClientProcedure) {
            const { warnings } = await runClientsProcedure(completionParams);
            if (warnings.length > 0) customWarnings = [...warnings];
          }
        } catch (reason) {
          setLoader(false);
          const message = getErrorMessage(reason);
          setShowAlert({
            show: true,
            message: message.message,
            status: message.status,
            iconColor: "error",
            closeAfterError: true,
          });
          return;
        }

        setLoader(false);

        if (customWarnings && customWarnings.length > 0) {
          const warningMessage = getWarningMessage(customWarnings);

          setShowAlert({
            show: true,
            message: warningMessage,
            status: 0,
            iconColor: "warning",
          });
        } else if (warnings && warnings.length > 0) {
          const warningMessage = getWarningMessage(warnings);

          setShowAlert({
            show: true,
            message: warningMessage,
            status: 0,
            iconColor: "warning",
          });
        } else {
          const message = translate("success", "Operacija baigta sėkmingai!");

          setShowAlert({
            show: true,
            message: message,
            status: 0,
            iconColor: "success",
          });
        }
      } catch (reason) {
        setLoader(false);
        const message = getErrorMessage(reason);
        setShowAlert({
          show: true,
          message: message.message,
          status: message.status,
          iconColor: "error",
        });
      }
    }, // eslint-disable-next-line
    [translate]
  );

  useEffect(
    () => {
      setLoader(true);

      getDocHeaderById(headerParams)
        .then(({ data }) => {
          const header = data[0];
          setHeaderData(header);
          if (data[0] && data[0].note) setNote(data[0].note);
        })
        .then(() => {
          const completionParams = {
            ...headerParams,
            operationType: headerData.operationType,
          };

          check(completionParams);
        })
        .catch((reason) => {
          setLoader(false);
          const message = getErrorMessage(reason);
          setShowAlert({
            show: true,
            message: message.message,
            status: message.status,
            iconColor: "error",
          });
        });
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const params = {
        clientDbId: userDbId,
        locale: locale,
        module: module,
        operationType: headerData.operationType,
      };

      if (headerData.operationType !== "") {
        canUserModifyQuantities(params)
          .then(({ data }) => {
            if (
              data.length > 0 &&
              data[0].canFinalizeWithQuantitiesModification &&
              (data[0].canFinalizeWithQuantitiesModification === 1 ||
                data[0].canFinalizeWithQuantitiesModification === "1")
            )
              setCanUserModify(true);

            if (
              data.length > 0 &&
              data[0].canFinalizeWithoutQuantitiesModification &&
              (data[0].canFinalizeWithoutQuantitiesModification === 1 ||
                data[0].canFinalizeWithoutQuantitiesModification === "1")
            )
              setCanUserCompleteWithErr(true);
          })
          .catch((reason) => {
            const message = getErrorMessage(reason);
            setShowAlert({
              show: true,
              message: message.message,
              status: message.status,
              iconColor: "error",
            });
          });
      }
    }, // eslint-disable-next-line
    [headerData.operationType]
  );

  React.useEffect(
    () => {
      const params = {
        clientDbId: userDbId,
        locale: locale,
        module: module,
        operationType: headerData.operationType,
      };

      getHeaderAdjustmentParams(params)
        .then(({ data }) => {
          if (data && data[0] && data[0].value1) {
            const options = data[0].value1;
            const descArray = options.split(/,\s*/).map((option: any) => {
              return { label: option, value: option };
            });
            setNoteList(descArray);
          }
        })
        .catch((reason) => {
          const message = getErrorMessage(reason);
          setShowAlert({
            show: true,
            message: message.message,
            status: message.status,
            iconColor: "error",
          });
        });
    }, // eslint-disable-next-line
    []
  );

  const handleCompletion = (option: 0 | 1 | 2) => {
    const completionParams = {
      ...headerParams,
      operationType: headerData.operationType,
      locale: locale,
      finalizeOption: option,
      note: note,
      departmentId: departmentId,
    };

    finalize(completionParams);
  };

  const handleErrorClose = () => {
    logoutUser(showAlert.status);
    if (showAlert.status !== 401) {
      showAlert.closeAfterError
        ? setRedirect(true)
        : setShowAlert({
            show: false,
            message: "",
            status: 0,
            iconColor: "error",
          });
    }
  };

  const handleCompletionClose = () => {
    setRedirect(true);
  };

  const handleNoteAuto = (event: React.SyntheticEvent, value: any) => {
    setNote(value && value.value ? value.value : "");
  };

  const handleNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const showError = (
    <Alert
      isOpen={showAlert.show}
      message={showAlert.message}
      onClose={
        showAlert.iconColor === "error"
          ? handleErrorClose
          : handleCompletionClose
      }
      iconColor={showAlert.iconColor}
    />
  );

  return (
    <div className={classes.mainContainer}>
      {showAlert.show && showError}

      {redirect && <Redirect to={link.list} />}

      <div className={classes.container}>
        <HeaderField
          data={headerData}
          link={showOk ? link.list : link.details}
          headerColor="grey"
          module={module}
        />
      </div>

      <div className={classes.container}>
        {noteList && noteList.length > 0 ? (
          <Autocomplete
            fullWidth
            options={noteList}
            getOptionLabel={(option: any) => option.value}
            classes={{ root: classes.autocomplete }}
            onChange={handleNoteAuto}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                otherColor="white"
                endAdornment={<Label name={translate("note", "Pastaba")} />}
                value={note}
              />
            )}
          />
        ) : (
          <TextField
            fullWidth
            otherColor="white"
            endAdornment={<Label name={translate("note", "Pastaba")} />}
            onChange={handleNote}
            multiline
            value={note}
          />
        )}
      </div>

      {showOk && (
        <div className={classes.container}>
          <Button fullWidth={true} onClick={() => handleCompletion(0)}>
            {translate("completed", "Baigti")}
          </Button>
        </div>
      )}

      {showWarning && (
        <div className={classes.mainContainer}>
          {canUserCompleteWithErr && (
            <div className={classes.container}>
              <Button
                fullWidth={true}
                onClick={() => handleCompletion(1)}
                otherColors="warning"
                startIcon={<PriorityHigh />}
              >
                {translate("completeWithErrors", "Baigti su netikslumais")}
              </Button>
            </div>
          )}

          {canUserModify && (
            <div className={classes.container}>
              <Button
                fullWidth={true}
                onClick={() => handleCompletion(2)}
                otherColors="error"
                startIcon={<PriorityHigh />}
              >
                {translate("completeWithAdjustments", "Baigti koreguojant")}
              </Button>
            </div>
          )}

          <InfiniteScrollList
            initialData={data}
            loadDataparams={loadDataparams}
          />
        </div>
      )}
    </div>
  );
}

export default CompletionDetails;
