import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

const selectLanguage = () => {
  let lang = "lt";
  const lastLang = localStorage.getItem("lastLanguage");

  if (lastLang !== null) lang = lastLang;
  return lang;
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(XHR)
  .init({
    lng: selectLanguage(),
    fallbackLng: "lt",
    load: "currentOnly",
    react: {
      // wait: true,
      useSuspense: true,
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
  });

export default i18n;
