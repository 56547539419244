import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@mui/icons-material";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthorizationError, useLoader } from "../../hooks";
import { getErrorMessage } from "../form/formUtils";
import {
  userClientDb,
  uiLanguage,
  refreshData,
  userDepartmentId,
} from "../../modules/auth/selectors/userSelector";
import { Alert, TextField } from "../index";
import * as routes from "../../app/routes";
import { HeaderField, ItemField, InfoField } from "./index";
import { moduleType } from "../../utils/modules";
import { useInfiniteScrollListStyles } from "./css";

interface IInfiniteScrollList {
  loadData?: (params: {
    [name: string]: any;
  }) => Promise<{ data: Array<any>; warnings: Array<any> }>;
  loadDataparams: {
    link?: string;
    linkId?: "operationCode";
    headerColor?: "grey" | "noColor";
    expandRows?: boolean;
    module?: moduleType;
    showOnlyNotCollected?: boolean;
    operationCode?: number | string;
    cellType?: "header" | "info" | "item";
    data?: any[];
    complete?: boolean;
    ref?: React.RefObject<HTMLDivElement>;
  };
  initialData?: Array<any>;
  externalFilter?: boolean;
}

function InfiniteScrollList(props: IInfiniteScrollList) {
  const { loadData, loadDataparams, externalFilter, initialData } = props;
  const [, setLoader] = useLoader();
  const [error, setError] = useState({ error: false, message: "", status: 0 });
  const userDb = useSelector(userClientDb);
  const locale = useSelector(uiLanguage);
  const userDbId = userDb ? userDb.id : 0;
  const departmentId = useSelector(userDepartmentId);
  const casheRowNumber = 30;
  const params = {
    ...loadDataparams,
    clientDbId: userDbId,
    locale,
    departmentId,
    top: casheRowNumber,
    pageNumber: 1,
  };
  const [pageNumber, setPageNumber] = useState(1);
  const [dataList, setDataList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [logoutUser] = useAuthorizationError();
  const [redirect, setRedirect] = useState(false);
  const refresh = useSelector(refreshData);
  const [filter, setFilter] = useState("");
  const { classes } = useInfiniteScrollListStyles();
  const [translate] = useTranslation();

  const getData = (paramsLoading: { [key: string]: any }) => {
    if (initialData) {
      setDataList(initialData);
      setHasMore(false);
      return;
    }

    if (loadData) {
      setLoader(true);
      loadData(paramsLoading)
        .then(({ data }) => {
          setLoader(false);

          if (loadDataparams && loadDataparams.showOnlyNotCollected === true) {
            const filteredData = data.filter(
              (item) =>
                item.isFullyCollected === 0 || item.isFullyCollected === "0"
            );

            if (filteredData.length) {
              setHasMore(
                filteredData[filteredData.length - 1].Row_number !==
                  filteredData[filteredData.length - 1].totalElements
              );
            } else setHasMore(false);
            setDataList([...paramsLoading.dataList, ...filteredData]);
          } else {
            if (data.length) {
              setHasMore(
                data[data.length - 1].Row_number !==
                  data[data.length - 1].totalElements
              );
            } else setHasMore(false);

            setDataList([...paramsLoading.dataList, ...data]);
          }

          setPageNumber(paramsLoading.pageNumber + 1);
        })
        .catch((reason) => {
          setLoader(false);
          const errMessage = getErrorMessage(reason);

          setError({
            error: true,
            message: errMessage.message,
            status: errMessage.status,
          });
        });
    }
  };

  const isExternalFilterPresent = () => externalFilter && filter !== "";

  React.useEffect(
    () => {
      setPageNumber(1);
      setDataList([]);
      let paramsLoading: { [key: string]: any } = { ...params, dataList: [] };

      if (isExternalFilterPresent()) {
        paramsLoading = {
          ...paramsLoading,
          filApp: `element1 like '%${filter}%' or element2 like '%${filter}%' `,
        };
      }

      getData(paramsLoading);
    }, // eslint-disable-next-line
    [refresh, filter, loadDataparams]
  );

  const fetchMoreData = () => {
    const paramsLoading = {
      ...params,
      pageNumber,
      dataList,
    };

    getData(paramsLoading);
  };

  const handleClose = () => {
    logoutUser(error.status);

    if (error.status !== 401) {
      setError({ error: false, message: "", status: 0 });
      if (!loadDataparams?.operationCode) {
        setRedirect(true);
      }
    }
  };

  const showError = (
    <Alert
      isOpen={error.error}
      message={error.message}
      iconColor="error"
      onClose={handleClose}
    />
  );

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <div className={classes.mainContainer}>
      {redirect ? (
        <Redirect
          to={{
            pathname: routes.ROUTE_OPTIONS.path,
            state: { from: routes.ROUTE_LOGIN.path },
          }}
        />
      ) : (
        <div className={classes.container}>
          {error.error && showError}

          {externalFilter && (
            <TextField
              onChange={handleFilter}
              endAdornment={<SearchOutlined />}
              otherColor="white"
              name="filter"
            />
          )}

          <div
            id="scrollableDiv"
            className={classes.scrollableDiv}
            ref={loadDataparams.ref}
          >
            {dataList.length ? (
              <InfiniteScroll
                dataLength={dataList.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {dataList.map((item, index) => (
                  <div key={index}>
                    {loadDataparams && loadDataparams.cellType === "info" && (
                      <InfoField data={item} />
                    )}
                    {loadDataparams && loadDataparams.cellType === "item" && (
                      <ItemField
                        data={item}
                        selectedRow={
                          loadDataparams.data &&
                          item.rowNumber === loadDataparams.data[0].rowNumber
                        }
                        complete={loadDataparams.complete}
                      />
                    )}
                    {loadDataparams && loadDataparams.cellType === "header" && (
                      <HeaderField data={item} {...loadDataparams} />
                    )}
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div className={classes.noRowsContainer}>
                <p className={classes.noRows}>
                  {translate("noRows", "Sąrašas tuščias")}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default InfiniteScrollList;
