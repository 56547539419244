import React, { useEffect, useState } from "react";
import { HeaderField, InfiniteScrollList } from "./table";
import {
  getDocDetails,
  getDocHeaderById,
  updateDocDetails,
} from "../modules/api/docListsandDetails";
import { ScannerForm } from "./form";
import {
  userClientDb,
  uiLanguage,
  userShowNotCollectedUsage,
  userSearchUsage,
  refreshData,
} from "../modules/auth/selectors/userSelector";
import { useSelector, useDispatch } from "react-redux";
import * as routes from "../app/routes";
import { useTranslation } from "react-i18next";
import { BaseLayout } from "../layouts";
import { Check } from "@mui/icons-material";
import Alert from "./Alert";
import { getErrorMessage } from "./form/formUtils";
import { useAuthorizationError, useLoader } from "../hooks";
import * as modules from "../utils/modules";
import { getModuleParams } from "../modules/api/parameters";
import {
  setUserExtraFieldsArray,
  setUserBarcodeSeparator,
  setUserRowDeletion,
} from "../modules/auth/slices/userSlice";
import { moduleType } from "../utils/modules";
import { Tab, Tabs, Badge } from "@mui/material";
import { SearchOutlined, QrCodeScannerOutlined } from "@mui/icons-material";
import { TextField, Label } from "./index";
import { useDocumentDetailsStyles } from "./css";

interface IDocumentDetails {
  operationCode: string;
  module: modules.moduleType;
  headerRoute: string;
}

function DocumentDetails(props: IDocumentDetails) {
  const { operationCode, module, headerRoute } = props;
  const id = operationCode ? operationCode : "";
  const showOnlyNotCollected = useSelector(userShowNotCollectedUsage);
  const refresh = useSelector(refreshData);
  const [logoutUser] = useAuthorizationError();
  const [translate] = useTranslation();
  const [data, setData] = useState();
  const [error, setError] = useState({ error: false, message: "", status: 0 });
  const userDb = useSelector(userClientDb);
  const locale = useSelector(uiLanguage);
  const useSearch = useSelector(userSearchUsage);
  const userDbId = userDb ? userDb.id : 0;
  const [, setLoader] = useLoader();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const { classes } = useDocumentDetailsStyles();
  const searchRef = React.useRef<HTMLInputElement>();
  const loadDataparams = React.useMemo<{
    operationCode: string;
    module: moduleType;
    data?: [];
    showOnlyNotCollected?: boolean;
    cellType: "header" | "info" | "item";
  }>(
    () => ({
      operationCode: id,
      module: module,
      data: data,
      showOnlyNotCollected: showOnlyNotCollected,
      cellType: "item",
      filApp: `element1 like '%${filter}%' or element2 like '%${filter}%' `,
    }),
    [id, module, data, showOnlyNotCollected, filter]
  );

  const [tab, setTab] = useState(0);

  const headerParams = {
    clientDbId: userDbId,
    locale: locale,
    operationCode: id,
    module: module,
  };
  const [headerData, setHeaderData] = useState({
    operationCode: "",
    operationType: "",
  });

  const moduleName = React.useMemo(() => {
    let name = "";
    let path = "/";
    switch (module) {
      case modules.MODULE_SALES:
        name = translate("sales", "Pardavimai");
        path = routes.ROUTE_SALES.path;
        break;
      case modules.MODULE_PURCHASES:
        name = translate("purchases", "Pirkimai");
        path = routes.ROUTE_PURCHASES.path;
        break;
      case modules.MODULE_INTERNAL:
        name = translate("insideAccounting", "Vidiniai");
        path = routes.ROUTE_INTERNAL.path;
        break;
      case modules.MODULE_SCANNER:
        name = translate("caches", "Kaupikliai");
        path = routes.ROUTE_SCANNER.path;
        break;
      case modules.MODULE_INVENTORY:
        name = translate("inventory", "Inventorizacija");
        path = routes.ROUTE_INVENTORY.path;
        break;
      case modules.MODULE_ASSEMBLY:
        name = translate("assembly", "Gamyba");
        path = routes.ROUTE_ASSEMBLY.path;
        break;
    }

    return { name: name, path: path };
  }, [module, translate]);

  useEffect(
    () => {
      setLoader(true);

      getDocHeaderById(headerParams)
        .then(({ data }) => {
          setLoader(false);
          setHeaderData(data[0]);
        })
        .catch((reason) => {
          setLoader(false);
          const errMessage = getErrorMessage(reason);
          setError({
            error: true,
            message: errMessage.message,
            status: errMessage.status,
          });
        });
    }, // eslint-disable-next-line
    [data, refresh]
  );

  const handleClose = () => {
    logoutUser(error.status);
    if (error.status !== 401) {
      setError({ error: false, message: "", status: 0 });
    }
  };

  const showError = (
    <Alert
      isOpen={error.error}
      message={error.message}
      iconColor="error"
      onClose={handleClose}
    />
  );

  const updateData = React.useCallback((updatedData) => {
    setData(updatedData);
  }, []);

  React.useEffect(
    () => {
      const queryParams = {
        locale: locale,
        clientDbId: userDbId,
        module: module,
      };
      setLoader(true);
      getModuleParams(queryParams)
        .then(({ data }) => {
          setLoader(false);

          if (data && data.length > 0) {
            dispatch(setUserExtraFieldsArray(data));

            const separator = data.filter(
              (sep: any) =>
                sep.parameter && sep.parameter.toUpperCase() === "SEPARATOR"
            );

            if (separator.length) {
              dispatch(setUserBarcodeSeparator(separator[0].code));
            } else dispatch(setUserBarcodeSeparator(";"));

            //delete rows
            const canDelete = data.find(
              (el: any) =>
                el.parameter && el.parameter.toUpperCase() === "DELETE"
            );

            if (canDelete && canDelete.code) {
              canDelete.code.toUpperCase() === ".T."
                ? dispatch(setUserRowDeletion(true))
                : dispatch(setUserRowDeletion(false));
            }
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }, // eslint-disable-next-line
    []
  );

  const handleTabChange = (e: React.SyntheticEvent, value: any) => {
    setTab(value);
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  React.useEffect(() => {
    if (searchRef.current) searchRef.current.focus();
  }, [tab]);

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.select();
  };

  return (
    <BaseLayout
      moduleName={moduleName.name}
      modulePath={moduleName.path}
      button={{
        btnIcon: <Check />,
        btnText: translate("complete", "Užbaigti"),
        btnLink: routes.ROUTE_COMPLETION.path + "/" + module + "/" + id,
      }}
    >
      {error.error && showError}

      <HeaderField
        data={headerData}
        link={headerRoute}
        headerColor="grey"
        module={module}
      />

      {useSearch && (
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          className={classes.container}
        >
          <Tab icon={<QrCodeScannerOutlined />} />
          <Tab
            icon={
              <Badge
                color="error"
                variant="dot"
                invisible={filter === ""}
                className={classes.badge}
              >
                <SearchOutlined />{" "}
              </Badge>
            }
          />
        </Tabs>
      )}

      {tab === 0 && (
        <ScannerForm
          module={module}
          operationCode={headerData.operationCode}
          operationType={headerData.operationType}
          userDb={userDbId}
          updateData={updateData}
          onSubmit={updateDocDetails}
        />
      )}

      {tab === 1 && (
        <TextField
          onChange={handleFilter}
          endAdornment={<Label name={translate("search", "Paieška")} />}
          otherColor="white"
          value={filter}
          ref={searchRef}
          onFocus={handleFocus}
        />
      )}

      <InfiniteScrollList
        loadData={getDocDetails}
        loadDataparams={loadDataparams}
      />
    </BaseLayout>
  );
}

export default DocumentDetails;
