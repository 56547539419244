import React, { useState } from "react";
import { Divider, FormControl, FormLabel, FormGroup } from "@mui/material";
import {
  Checkbox,
  NavigationBar,
  InfoButton,
  Select,
} from "../../../components";
import DbList from "./DbList";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserDbId,
  setUserAutoMode,
  setUserObjectUsage,
  setUserLotUsage,
  setUserShowOnlyNotCollected,
  setUserExpandRows,
  setUserSemiAutoMode,
  setUserCopyLot,
  setUserCopyObject,
  setUserFocusAfterBarcode,
  setUserExtraFieldsUsage,
  setUserCameraUsage,
  setUserSearchUsage,
} from "../../auth/slices/userSlice";
import { useSettingsStyles } from "../css";
import {
  userId,
  userClientDb,
  userDepartmentId,
} from "../../auth/selectors/userSelector";
import { useHistory, useLocation } from "react-router-dom";
import * as routes from "../../../app/routes";
import { SettingsOutlined } from "@mui/icons-material";
import { isAlreadySet, otherUsersSettings } from "./utils";
import DepartmentList from "./DepartmentList";

function Settings() {
  const { classes } = useSettingsStyles();
  const user = useSelector(userId);
  const userDb = useSelector(userClientDb);
  const departmentId = useSelector(userDepartmentId);
  const [dbChange, setDbChange] = useState(false);
  const [depChange, setDepChange] = useState(false);
  const [translate] = useTranslation();
  const dbRef = React.useRef<string | undefined>();
  const depRef = React.useRef<string | undefined>();
  const history = useHistory();
  const { state: locationState } = useLocation<{ from?: string }>();
  const dispatch = useDispatch();
  const [localStorage] = useLocalStorage("userDb");
  const [autoLS, setAutoLS] = useLocalStorage("autoMode", [
    { userId: user, isUsed: false },
  ]);
  const [semiAutoLS, setSemiAutoLS] = useLocalStorage("semiAutoMode", [
    { userId: user, isUsed: false },
  ]);
  const [objectLS, setObjectLS] = useLocalStorage("useObject", [
    { userId: user, isUsed: false },
  ]);
  const [lotLS, setLotLS] = useLocalStorage("useLot", [
    { userId: user, isUsed: false },
  ]);
  const [extraLS, setExtraLS] = useLocalStorage("useExtraFields", [
    { userId: user, isUsed: false },
  ]);
  const [focusAfterBrLS, setFocusAfterBrLS] = useLocalStorage(
    "focusAfterBarcode",
    [{ userId: user, field: "quantity" }]
  );
  const [showNotCollectedLS, setshowNotCollectedLS] = useLocalStorage(
    "showOnlyNotCollected",
    [{ userId: user, show: false }]
  );
  const [expandRowsLS, setExpandRowsLS] = useLocalStorage("expandRows", [
    { userId: user, allow: false },
  ]);
  const [copyLotLS, setCopyLotLS] = useLocalStorage("copyLot", [
    { userId: user, allow: false },
  ]);
  const [copyObjectLS, setCopyObjectLS] = useLocalStorage("copyObject", [
    { userId: user, allow: false },
  ]);
  const [useCameraLS, setUseCameraLS] = useLocalStorage("useCamera", [
    { userId: user, isUsed: false },
  ]);
  const [savedObject, setSavedObject] = useLocalStorage("savedObject", [
    { userId: user, value: "" },
  ]);
  const [savedLot, setSavedLot] = useLocalStorage("savedLot", [
    { userId: user, value: "" },
  ]);
  const [useSearchLs, setUseSearchLs] = useLocalStorage("useSearch", [
    { userId: user, isUsed: false },
  ]);
  const [checkAuto, setCheckAuto] = useState(false);
  const [checkSemiAuto, setCheckSemiAuto] = useState(false);
  const [checkCamera, setCheckCamera] = useState(false);
  const [checkObject, setCheckObject] = useState(false);
  const [checkLot, setCheckLot] = useState(false);
  const [checkExtra, setCheckExtra] = useState(false);
  const [checkNotCollected, setCheckNotCollected] = useState(false);
  const [checkExpandRows, setCheckExpandRows] = useState(false);
  const [checkCopyLot, setCheckCopyLot] = useState(false);
  const [checkCopyObject, setCheckCopyObject] = useState(false);
  const [checkSearch, setCheckSearch] = useState(false);
  const [focusAfterBarcode, setFocusAfterBarcode] = useState("quantity");

  const fieldsList = React.useMemo(
    () => [
      { value: "quantity", label: translate("quantity", "Kiekis") },
      { value: "object", label: translate("object", "Objektas") },
      { value: "lot", label: translate("lot", "Serija") },
    ],
    [translate]
  );

  React.useEffect(() => {
    const ref = dbRef.current;
    dbRef.current = JSON.stringify(userDb ? userDb.id : undefined);

    if (ref && userDb && JSON.stringify(userDb.id) !== ref) {
      setDbChange(true);
    }
  }, [userDb, setDbChange]);

  React.useEffect(() => {
    const ref = depRef.current;
    depRef.current = departmentId;

    if (ref && departmentId && departmentId !== ref) {
      setDepChange(true);
    }
  }, [departmentId, setDepChange]);

  React.useEffect(
    () => {
      //User Db setting
      const isUser = isAlreadySet(localStorage, user);

      if (isUser.length > 0) {
        dispatch(setUserDbId(isUser[0]));
      }
    }, // eslint-disable-next-line
    [dispatch, localStorage]
  );

  React.useEffect(() => {
    //AutoMode setting
    const isAuto = isAlreadySet(autoLS, user);
    dispatch(setUserAutoMode(isAuto.length > 0 ? isAuto[0].isUsed : false));
    if (isAuto.length > 0) setCheckAuto(isAuto[0].isUsed);
  }, [dispatch, autoLS, user]);

  React.useEffect(() => {
    //SemiAutoMode setting
    const isSemiAuto = isAlreadySet(semiAutoLS, user);
    dispatch(
      setUserSemiAutoMode(isSemiAuto.length > 0 ? isSemiAuto[0].isUsed : false)
    );
    if (isSemiAuto.length > 0) setCheckSemiAuto(isSemiAuto[0].isUsed);
  }, [dispatch, semiAutoLS, user]);

  React.useEffect(() => {
    //useCamera setting
    const useCamera = isAlreadySet(useCameraLS, user);
    dispatch(
      setUserCameraUsage(useCamera.length > 0 ? useCamera[0].isUsed : false)
    );
    if (useCamera.length > 0) setCheckCamera(useCamera[0].isUsed);
  }, [dispatch, useCameraLS, user]);

  React.useEffect(() => {
    //Object setting
    const isObject = isAlreadySet(objectLS, user);

    dispatch(
      setUserObjectUsage(isObject.length > 0 ? isObject[0].isUsed : false)
    );
    if (isObject.length > 0) setCheckObject(isObject[0].isUsed);
  }, [dispatch, objectLS, user]);

  React.useEffect(() => {
    //Lot setting
    const isLot = isAlreadySet(lotLS, user);

    dispatch(setUserLotUsage(isLot.length > 0 ? isLot[0].isUsed : false));
    if (isLot.length > 0) setCheckLot(isLot[0].isUsed);
  }, [dispatch, lotLS, user]);

  React.useEffect(() => {
    //extraFields setting
    const isExtra = isAlreadySet(extraLS, user);

    dispatch(
      setUserExtraFieldsUsage(isExtra.length > 0 ? isExtra[0].isUsed : false)
    );
    if (isExtra.length > 0) setCheckExtra(isExtra[0].isUsed);
  }, [dispatch, extraLS, user]);

  React.useEffect(() => {
    //showNotCollected setting
    const showNotCollected = isAlreadySet(showNotCollectedLS, user);

    dispatch(
      setUserShowOnlyNotCollected(
        showNotCollected.length > 0 ? showNotCollected[0].show : false
      )
    );

    if (showNotCollected.length > 0)
      setCheckNotCollected(showNotCollected[0].show);
  }, [dispatch, showNotCollectedLS, user]);

  React.useEffect(
    () => {
      //ExpandRows setting
      const expandRows = isAlreadySet(expandRowsLS, user);

      dispatch(
        setUserExpandRows(expandRows.length > 0 ? expandRows[0].allow : false)
      );

      if (expandRows.length > 0) setCheckExpandRows(expandRows[0].allow);
    }, // eslint-disable-next-line
    [dispatch, expandRowsLS, user]
  );

  React.useEffect(
    () => {
      //Search usage setting
      const search = isAlreadySet(useSearchLs, user);

      dispatch(
        setUserSearchUsage(search.length > 0 ? search[0].isUsed : false)
      );

      if (search.length > 0) setCheckSearch(search[0].isUsed);
    }, // eslint-disable-next-line
    [dispatch, useSearchLs, user]
  );

  React.useEffect(
    () => {
      //Focus atfter barcode setting
      const focusAfter = isAlreadySet(focusAfterBrLS, user);

      dispatch(
        setUserFocusAfterBarcode(
          focusAfter.length > 0 ? focusAfter[0].field : "quantity"
        )
      );

      if (focusAfter.length > 0) setFocusAfterBarcode(focusAfter[0].field);
    }, // eslint-disable-next-line
    [dispatch, focusAfterBrLS, user]
  );

  React.useEffect(
    () => {
      //CopyLot setting
      const copyLot = isAlreadySet(copyLotLS, user);

      dispatch(setUserCopyLot(copyLot.length > 0 ? copyLot[0].allow : false));

      if (copyLot.length > 0) setCheckCopyLot(copyLot[0].allow);
    }, // eslint-disable-next-line
    [dispatch, copyLotLS, user]
  );

  React.useEffect(
    () => {
      //CopyObject setting
      const copyObject = isAlreadySet(copyObjectLS, user);

      dispatch(
        setUserCopyObject(copyObject.length > 0 ? copyObject[0].allow : false)
      );

      if (copyObject.length > 0) setCheckCopyObject(copyObject[0].allow);
    }, // eslint-disable-next-line
    [dispatch, copyObjectLS, user]
  );

  const handleAutomode = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserAutoMode(event.target.checked));
    const otherUsers = otherUsersSettings(autoLS, user);
    setAutoLS([...otherUsers, { userId: user, isUsed: event.target.checked }]);

    //Automode and SemiAutoMode can't be both true at the same time. Only can be both false at the same time
    if (event.target.checked === true && checkSemiAuto === true) {
      dispatch(setUserSemiAutoMode(false));
      const otherUsers = otherUsersSettings(semiAutoLS, user);
      setSemiAutoLS([...otherUsers, { userId: user, isUsed: false }]);
    }
  };

  const handleSemiAutomode = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserSemiAutoMode(event.target.checked));
    const otherUsers = otherUsersSettings(semiAutoLS, user);
    setSemiAutoLS([
      ...otherUsers,
      { userId: user, isUsed: event.target.checked },
    ]);
    if (event.target.checked === true && checkAuto === true) {
      dispatch(setUserAutoMode(false));
      const otherUsers = otherUsersSettings(autoLS, user);
      setAutoLS([...otherUsers, { userId: user, isUsed: false }]);
    }
  };

  const handleCamera = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserCameraUsage(event.target.checked));
    const otherUsers = otherUsersSettings(useCameraLS, user);
    setUseCameraLS([
      ...otherUsers,
      { userId: user, isUsed: event.target.checked },
    ]);
  };

  const handleObject = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserObjectUsage(event.target.checked));
    const otherUsers = otherUsersSettings(objectLS, user);
    setObjectLS([
      ...otherUsers,
      { userId: user, isUsed: event.target.checked },
    ]);
  };

  const handleLot = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserLotUsage(event.target.checked));
    const otherUsers = otherUsersSettings(lotLS, user);
    setLotLS([...otherUsers, { userId: user, isUsed: event.target.checked }]);
  };

  const handleExtra = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserExtraFieldsUsage(event.target.checked));
    const otherUsers = otherUsersSettings(extraLS, user);
    setExtraLS([...otherUsers, { userId: user, isUsed: event.target.checked }]);
  };

  const handleShowNotCollected = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setUserShowOnlyNotCollected(event.target.checked));
    const otherUsers = otherUsersSettings(showNotCollectedLS, user);

    setshowNotCollectedLS([
      ...otherUsers,
      { userId: user, show: event.target.checked },
    ]);
  };

  const handleExpand = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserExpandRows(event.target.checked));
    const otherUsers = otherUsersSettings(expandRowsLS, user);
    setExpandRowsLS([
      ...otherUsers,
      { userId: user, allow: event.target.checked },
    ]);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserSearchUsage(event.target.checked));
    const otherUsers = otherUsersSettings(useSearchLs, user);
    setUseSearchLs([
      ...otherUsers,
      { userId: user, isUsed: event.target.checked },
    ]);
  };

  const handleCopyLot = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserCopyLot(event.target.checked));
    const otherUsers = otherUsersSettings(copyLotLS, user);
    setCopyLotLS([
      ...otherUsers,
      { userId: user, allow: event.target.checked },
    ]);
    if (!event.target.checked) {
      const others = otherUsersSettings(savedLot, user);
      setSavedLot([...others, { userId: user, value: "" }]);
    }
  };

  const handleCopyObject = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserCopyObject(event.target.checked));
    const otherUsers = otherUsersSettings(copyObjectLS, user);
    setCopyObjectLS([
      ...otherUsers,
      { userId: user, allow: event.target.checked },
    ]);
    if (!event.target.checked) {
      const others = otherUsersSettings(savedObject, user);
      setSavedObject([...others, { userId: user, value: "" }]);
    }
  };

  const handleBack = () => {
    if (
      locationState.from !== routes.ROUTE_LOGIN.path &&
      !dbChange &&
      !depChange
    ) {
      history.go(-2);
    } else {
      history.goBack();
    }
  };

  const handleFocusOnNext = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: "quantity" | "object" | "lot" = "quantity";

    if (event.target.value === "object" || event.target.value === "lot")
      value = event.target.value;

    dispatch(setUserFocusAfterBarcode(value));
    setFocusAfterBarcode(value);
    const otherUsers = otherUsersSettings(focusAfterBrLS, user);
    setFocusAfterBrLS([
      ...otherUsers,
      { userId: user, field: event.target.value },
    ]);
  };

  return (
    <div>
      <NavigationBar
        moduleName={translate("settings", "Nustatymai")}
        closeButton={handleBack}
        moduleIcon={<SettingsOutlined fontSize="small" />}
      />

      <Divider variant="fullWidth" className={classes.divider} />

      <div className={classes.root}>
        <div className={classes.container}>
          <FormControl focused={false}>
            <FormLabel component="legend" className={classes.groupLegend}>
              {translate("scanningModes", "Skenavimo rėžimai:")}
            </FormLabel>
            <FormGroup className={classes.settingsGroup}>
              <Checkbox
                label={translate("autoMode", "Automatinis")}
                onChange={handleAutomode}
                checked={checkAuto}
                value={checkAuto}
                rightElement={
                  <InfoButton
                    text={translate(
                      "autoModeInfo",
                      'Nuskenuoti duomenys fiksuojami <ENTER> klavišo paspaudimu esant bet kuriame lauke. Mygtukas "Tęsti" nerodomas.'
                    )}
                  />
                }
              />

              <Checkbox
                label={translate("semiAutoMode", "Dalinai automatinis")}
                onChange={handleSemiAutomode}
                checked={checkSemiAuto}
                value={checkSemiAuto}
                rightElement={
                  <InfoButton
                    text={translate(
                      "semiAutomodeInfo",
                      'Nuskenuoti duomenys fiksuojami <ENTER> klavišo paspaudimu esant paskutiniame lauke. Mygtukas "Tęsti" nerodomas.'
                    )}
                  />
                }
              />

              <div className={classes.fieldsList}>
                <Select
                  options={fieldsList}
                  onChange={handleFocusOnNext}
                  value={focusAfterBarcode}
                  label={translate(
                    "focusAfterBarcode",
                    "Nuskenavus barkodą peršokti į"
                  )}
                  fullWidth
                />
              </div>
            </FormGroup>
          </FormControl>

          <FormControl focused={false}>
            <FormLabel component="legend" className={classes.groupLegend}>
              {translate("showAdditionalFields", "Papildomų laukų rodymas:")}
            </FormLabel>
            <FormGroup className={classes.settingsGroup}>
              <Checkbox
                label={translate("object", "Objektas")}
                onChange={handleObject}
                checked={checkObject}
                value={checkObject}
              />

              <Checkbox
                label={translate("lot", "Serija")}
                onChange={handleLot}
                checked={checkLot}
                value={checkLot}
              />

              <Checkbox
                label={translate("extraFields", "Papildoma informacija")}
                onChange={handleExtra}
                checked={checkExtra}
                value={checkExtra}
              />

              <Checkbox
                label={translate("useSearchField", "Paieškos laukas dokumente")}
                onChange={handleSearch}
                checked={checkSearch}
                value={checkSearch}
              />
            </FormGroup>
          </FormControl>

          <FormControl focused={false}>
            <FormLabel component="legend" className={classes.groupLegend}>
              {translate("fieldsValues", "Laukų reikšmės:")}
            </FormLabel>
            <FormGroup className={classes.settingsGroup}>
              <Checkbox
                label={translate(
                  "copyLastScannedObject",
                  "Objektas - kopijuoti reikšmę"
                )}
                onChange={handleCopyObject}
                checked={checkCopyObject}
                value={checkCopyObject}
                rightElement={
                  <InfoButton
                    text={translate(
                      "lastScannedValue",
                      "Atmintyje saugoma paskutinė nuskenuota reikšmė. Skenuojant naują prekę ši reikšmė išlieka."
                    )}
                  />
                }
              />

              <Checkbox
                label={translate(
                  "copyLastScannedLot",
                  "Serija - kopijuoti reikšmę"
                )}
                onChange={handleCopyLot}
                checked={checkCopyLot}
                value={checkCopyLot}
                rightElement={
                  <InfoButton
                    text={translate(
                      "lastScannedValue",
                      "Atmintyje saugoma paskutinė nuskenuota reikšmė. Skenuojant naują prekę ši reikšmė išlieka."
                    )}
                  />
                }
              />
            </FormGroup>
          </FormControl>

          <FormControl focused={false}>
            <FormLabel component="legend" className={classes.groupLegend}>
              {translate("device", "Įrenginys:")}
            </FormLabel>
            <FormGroup className={classes.settingsGroup}>
              <Checkbox
                label={translate("turnOnCamera", "Naudoti įrenginio kamera")}
                onChange={handleCamera}
                checked={checkCamera}
                value={checkCamera}
                rightElement={
                  <InfoButton
                    text={translate(
                      "cameraInfo",
                      "Laukų skenavimui naudojama įrenginio kamera."
                    )}
                  />
                }
              />
            </FormGroup>
          </FormControl>

          <FormControl focused={false}>
            <FormLabel component="legend" className={classes.groupLegend}>
              {translate("otherSettings", "Kiti nustatymai:")}
            </FormLabel>
            <FormGroup className={classes.settingsGroup}>
              <Checkbox
                label={translate(
                  "showOnlyNotFullyCollected",
                  "Rodyti tik neatriktas prekes"
                )}
                onChange={handleShowNotCollected}
                checked={checkNotCollected}
                value={checkNotCollected}
              />

              <Checkbox
                label={translate(
                  "allowRowHeightChanges",
                  "Praplėsti eilutę priklausomai nuo turinio"
                )}
                onChange={handleExpand}
                checked={checkExpandRows}
                value={checkExpandRows}
              />

              <DepartmentList />

              <div className={classes.dbList}>
                <DbList />
              </div>
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default Settings;
