import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export interface ICreateSalesPurchasesOperation {
  clientDbId: number;
  locale: string;
  clientCode: string;
  module: string;
  documentType: string;
  description: string;
}

export const createSalesPurchasesOperation = (
  params: ICreateSalesPurchasesOperation
) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_CREATE_RO_PO_DOC",
    data: {
      documentType: params.documentType,
      description: params.description,
      clientCode: params.clientCode,
      module: params.module,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IGetParamsList {
  clientDbId: number;
  locale: string;
  module: string;
}

export const getSalesPurchasesParams = (params: IGetParamsList) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_RO_PO_PARAMS",
    data: {
      module: params.module,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IGetClientsList {
  clientDbId: number;
  module: string;
  pageNumber: number;
  fil?: string;
}

export const getClientsList = (
  params: IGetClientsList,
  config?: Record<string, unknown>
) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_RO_PO_CLIENTS",
    data: {
      module: params.module,
      pageNumber: params.pageNumber,
      fil: params.fil || "",
      top: 100,
    },
  };

  return client
    .post("/client/application", query, config)
    .then(formatAppListResponse);
};
