import React from "react";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

interface IListboxComponent {
  children?: React.ReactNode;
  hasNextPage?: boolean;
  isNextPageLoading?: boolean;
  loadNextPage?: (startIndex: number, stopIndex: number) => Promise<any> | null;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props: IListboxComponent, ref) {
    const { children, hasNextPage, isNextPageLoading, loadNextPage, ...other } =
      props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.between(0, 300), {
      noSsr: true,
    });
    const smScreen = useMediaQuery(theme.breakpoints.between(301, 600), {
      noSsr: true,
    });
    const itemCount = hasNextPage ? itemData.length + 1 : itemData.length;
    const itemSize = xsScreen || smScreen ? 48 : 36;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index: number) =>
      !hasNextPage || index < itemData.length;

    const LISTBOX_PADDING = 8; // px

    // Render an item or a loading indicator.
    const Item = (props: ListChildComponentProps) => {
      const { data, index, style } = props;

      return data[index]
        ? React.cloneElement(data[index], {
            style: {
              ...style,
              top: (style.top as number) + LISTBOX_PADDING,
            },
          })
        : null;
    };

    const getChildSize = (child: React.ReactNode) => {
      if (
        xsScreen &&
        React.isValidElement(child) &&
        child.props.children[1].length > 30
      )
        return 76;
      else if (
        smScreen &&
        React.isValidElement(child) &&
        child.props.children[1].length > 60
      )
        return 57;

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          // @ts-ignore
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref: infRef }) => (
            <div ref={infRef}>
              <OuterElementContext.Provider value={other}>
                <VariableSizeList
                  itemData={itemData}
                  height={getHeight() + 2 * LISTBOX_PADDING}
                  width="100%"
                  ref={gridRef}
                  outerElementType={OuterElementType}
                  innerElementType="ul"
                  itemSize={(index) => getChildSize(itemData[index])}
                  // itemSize={(index) => 50}
                  overscanCount={5}
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                >
                  {Item}
                </VariableSizeList>
              </OuterElementContext.Provider>
            </div>
          )}
        </InfiniteLoader>
      </div>
    );
  }
);

export default ListboxComponent;
