import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "i18next";
import type { AppDispatch, AppThunk } from "../../../app/store/store";

export {};

export interface ITokenPayload {
  authorities: [];
  sub: string | undefined;
  iat: number;
  exp: number;
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  clientId: number;
  device: string | undefined;
  application: string | undefined;
}

export interface IUserPayload {
  userId: string;
  fullName: string;
  email: string;
  clientDb?: {
    id: string;
    name: string;
  };
}

export interface IUserState {
  error: string | undefined;
  uiLanguage: string;
  autoMode: boolean;
  semiAutoMode: boolean;
  useObject: boolean;
  useLot: boolean;
  user: IUserPayload | null;
  showOnlyNotCollected: boolean;
  expandRows: boolean;
  copyLot: boolean;
  copyObject: boolean;
  refreshData: boolean;
  focusAfterBarcode: "quantity" | "object" | "lot";
  useExtraFields: boolean;
  extraFieldsArray: [];
  useCamera: boolean;
  cameraId: string;
  departmentId: string;
  barcodeSeparator: string;
  useSearch: boolean;
  deleteRow: boolean;
}

const initialUserLocalItem = localStorage.getItem("lastUser");
let initialUser = null;
try {
  if (initialUserLocalItem) {
    initialUser = JSON.parse(initialUserLocalItem);
  }
  // eslint-disable-next-line no-empty
} catch (err) {}

const initialState: IUserState = {
  error: undefined,
  uiLanguage: "lt",
  autoMode: false,
  semiAutoMode: false,
  useObject: false,
  useLot: false,
  showOnlyNotCollected: false,
  expandRows: false,
  copyLot: false,
  copyObject: false,
  refreshData: false,
  focusAfterBarcode: "quantity",
  useExtraFields: false,
  extraFieldsArray: [],
  useCamera: false,
  cameraId: "",
  departmentId: "",
  barcodeSeparator: ";",
  useSearch: false,
  user: initialUser,
  deleteRow: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUserPayload>) {
      state.user = action.payload;
    },
    clearUser(state) {
      state.user = null;
    },
    setUiLanguage(state, action: PayloadAction<string>) {
      state.uiLanguage = action.payload;
    },
    setAutoMode(state, action: PayloadAction<boolean>) {
      state.autoMode = action.payload;
    },
    setSemiAutoMode(state, action: PayloadAction<boolean>) {
      state.semiAutoMode = action.payload;
    },
    setObject(state, action: PayloadAction<boolean>) {
      state.useObject = action.payload;
    },
    setLot(state, action: PayloadAction<boolean>) {
      state.useLot = action.payload;
    },
    setShowOnlyNotCollected(state, action: PayloadAction<boolean>) {
      state.showOnlyNotCollected = action.payload;
    },
    setExpandRows(state, action: PayloadAction<boolean>) {
      state.expandRows = action.payload;
    },
    setCopyLot(state, action: PayloadAction<boolean>) {
      state.copyLot = action.payload;
    },
    setCopyObject(state, action: PayloadAction<boolean>) {
      state.copyObject = action.payload;
    },
    setRefreshData(state, action: PayloadAction<boolean>) {
      state.refreshData = action.payload;
    },
    setFocusAfterBarcode(
      state,
      action: PayloadAction<"quantity" | "object" | "lot">
    ) {
      state.focusAfterBarcode = action.payload;
    },
    setDbId(
      state,
      action: PayloadAction<{ id: number; name: string; userId: string }>
    ) {
      if (state.user) {
        state.user.clientDb = {
          id: action.payload.id.toString(),
          name: action.payload.name,
        };
      }
    },
    setExtraFields(state: any, action: PayloadAction<boolean>) {
      state.useExtraFields = action.payload;
    },
    setExtraFieldsArray(state: any, action: PayloadAction<Array<any>>) {
      state.extraFieldsArray = action.payload;
    },
    setCamera(state, action: PayloadAction<boolean>) {
      state.useCamera = action.payload;
    },
    setCameraId(state, action: PayloadAction<string>) {
      state.cameraId = action.payload;
    },
    setDepartmentId(state, action: PayloadAction<string>) {
      state.departmentId = action.payload;
    },
    setBarcodeSeparator(state, action: PayloadAction<string>) {
      state.barcodeSeparator = action.payload;
    },
    setSearch(state: any, action: PayloadAction<boolean>) {
      state.useSearch = action.payload;
    },
    setDeleteRow(state: any, action: PayloadAction<boolean>) {
      state.deleteRow = action.payload;
    },
  },
});

export const setUserDetails =
  (user: IUserPayload): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setUser(user));
    localStorage.setItem("lastUser", JSON.stringify(user));
  };

export const clearUserDetails = () => async (dispatch: AppDispatch) =>
  dispatch(userSlice.actions.clearUser());

export const setUiLanguage =
  (language: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    i18n
      .changeLanguage(language)
      .then(() => {
        dispatch(userSlice.actions.setUiLanguage(language));
        localStorage.setItem("lastLanguage", language);
      })
      .catch((error) => {
        console.error(
          `failed to switch UI language with error: ${error.message}`
        );
      });
  };

export const setUserDbId =
  (userDb: { id: number; name: string; userId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setDbId(userDb));
  };

export const setUserAutoMode =
  (modeOn: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setAutoMode(modeOn));
  };

export const setUserSemiAutoMode =
  (modeOn: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setSemiAutoMode(modeOn));
  };

export const setUserObjectUsage =
  (useObect: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setObject(useObect));
  };

export const setUserLotUsage =
  (useLOt: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setLot(useLOt));
  };

export const setUserShowOnlyNotCollected =
  (show: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setShowOnlyNotCollected(show));
  };

export const setUserExpandRows =
  (allow: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setExpandRows(allow));
  };

export const setUserCopyLot =
  (allow: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setCopyLot(allow));
  };

export const setUserCopyObject =
  (allow: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setCopyObject(allow));
  };

export const setUserRefreshData =
  (refresh: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setRefreshData(refresh));
  };

export const setUserFocusAfterBarcode =
  (field: "quantity" | "object" | "lot"): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setFocusAfterBarcode(field));
  };

export const setUserExtraFieldsUsage =
  (useExtra: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setExtraFields(useExtra));
  };

export const setUserExtraFieldsArray =
  (useExtra: []): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setExtraFieldsArray(useExtra));
  };

export const setUserCameraUsage =
  (useCamera: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setCamera(useCamera));
  };

export const setUserCameraId =
  (cameraId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setCameraId(cameraId));
  };

export const setUserDepartmentId =
  (departmentId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setDepartmentId(departmentId));
  };

export const setUserBarcodeSeparator =
  (separator: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setBarcodeSeparator(separator));
  };

export const setUserSearchUsage =
  (useSearch: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setSearch(useSearch));
  };

export const setUserRowDeletion =
  (deleteRow: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setDeleteRow(deleteRow));
  };

export default userSlice.reducer;
