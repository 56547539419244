import { RootState } from "../../../app/store/rootReducer";

export const user = (state: RootState) => state.user.user;
export const userId = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  return user;
};
export const username = (state: RootState) => state.user.user?.email;
export const userFullName = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.fullName;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).fullName;

  return user;
};
export const isLoggedIn = (state: RootState) => {
  return !!state.user.user?.userId;
};

export const uiLanguage = (state: RootState) => state.user.uiLanguage;
export const refreshData = (state: RootState) => state.user.refreshData;

export const userClientDb = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateUser = state.user.user?.userId;
  let user: string | undefined;

  if (stateUser) user = stateUser;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const lastDb = localStorage.getItem("userDb");
  const stateDb = state.user.user?.clientDb;
  let userDb: { id: number } | undefined;

  if (stateDb) userDb = { id: parseInt(stateDb.id) };

  if (!userDb && lastDb) {
    const db = JSON.parse(lastDb);
    let findDb = [];

    if (Array.isArray(db))
      findDb = db.filter((index: any) => index.userId === user);

    if (findDb.length > 0 && findDb[0].id)
      userDb = { id: parseInt(findDb[0].id) };
  }

  return userDb;
};

export const userAutoMode = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateAutoMode = state.user.autoMode;
  const storage = localStorage.getItem("autoMode");
  let autoMode = false;

  if (stateAutoMode) autoMode = stateAutoMode;
  if (!stateAutoMode && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];
    autoMode =
      userInfo.length > 0 && userInfo[0].isUsed ? userInfo[0].isUsed : false;
  }

  return autoMode;
};

export const userSemiAutoMode = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateSemiAutoMode = state.user.semiAutoMode;
  const storage = localStorage.getItem("semiAutoMode");
  let semiAutoMode = false;

  if (stateSemiAutoMode) semiAutoMode = stateSemiAutoMode;
  if (!stateSemiAutoMode && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];
    semiAutoMode =
      userInfo.length > 0 && userInfo[0].isUsed ? userInfo[0].isUsed : false;
  }

  return semiAutoMode;
};

export const userLotUsage = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateLot = state.user.useLot;
  const storage = localStorage.getItem("useLot");
  let lot = false;

  if (stateLot) lot = stateLot;
  if (!stateLot && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    lot =
      userInfo.length > 0 && userInfo[0].isUsed ? userInfo[0].isUsed : false;
  }
  return lot;
};

export const userObjectUsage = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateObject = state.user.useObject;
  const storage = localStorage.getItem("useObject");
  let object = false;

  if (stateObject) object = stateObject;
  if (!stateObject && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    object =
      userInfo.length > 0 && userInfo[0].isUsed ? userInfo[0].isUsed : false;
  }

  return object;
};

export const userShowNotCollectedUsage = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateRows = state.user.showOnlyNotCollected;
  const storage = localStorage.getItem("showOnlyNotCollected");
  let showOnlyNotCollected = false;

  if (stateRows) showOnlyNotCollected = stateRows;
  if (!stateRows && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    showOnlyNotCollected =
      userInfo.length > 0 && userInfo[0].show ? userInfo[0].show : false;
  }

  return showOnlyNotCollected;
};

export const userExpandRows = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateRows = state.user.expandRows;
  const storage = localStorage.getItem("expandRows");
  let expandRows = false;

  if (stateRows) expandRows = stateRows;
  if (!stateRows && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    expandRows =
      userInfo.length > 0 && userInfo[0].allow ? userInfo[0].allow : false;
  }

  return expandRows;
};

export const userCopyLot = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateCopyLot = state.user.copyLot;
  const storage = localStorage.getItem("copyLot");
  let copyLot = false;

  if (stateCopyLot) copyLot = stateCopyLot;
  if (!stateCopyLot && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    copyLot =
      userInfo.length > 0 && userInfo[0].allow ? userInfo[0].allow : false;
  }

  return copyLot;
};

export const userCopyObject = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateCopyObject = state.user.copyLot;
  const storage = localStorage.getItem("copyObject");
  let copyObject = false;

  if (stateCopyObject) copyObject = stateCopyObject;
  if (!stateCopyObject && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    copyObject =
      userInfo.length > 0 && userInfo[0].allow ? userInfo[0].allow : false;
  }

  return copyObject;
};

export const userFocusAfterBarcode = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateFocusAfter = state.user.focusAfterBarcode;
  const storage = localStorage.getItem("focusAfterBarcode");
  let fieldAfterBarcode = "quantity";

  if (stateFocusAfter !== "quantity") fieldAfterBarcode = stateFocusAfter;
  if (stateFocusAfter === "quantity" && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    fieldAfterBarcode =
      userInfo.length > 0 && userInfo[0].field ? userInfo[0].field : "quantity";
  }

  return fieldAfterBarcode;
};

export const userExtraFieldsUsage = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateExtra = state.user.useExtraFields;
  const storage = localStorage.getItem("useExtraFields");
  let extra = false;

  if (stateExtra) extra = stateExtra;
  if (!stateExtra && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    extra =
      userInfo.length > 0 && userInfo[0].isUsed ? userInfo[0].isUsed : false;
  }
  return extra;
};

export const extraFieldsArray = (state: RootState) =>
  state.user.extraFieldsArray;

export const useExtraField1 = (state: RootState) => {
  const isExtra: Array<{
    [name: string]: any;
  }> = state.user.extraFieldsArray.filter(
    (field: any) => field.parameter.toUpperCase() === "PAP1"
  );
  let extra = { isUsed: false, type: "", name: "" };

  if (isExtra.length > 0)
    extra = { isUsed: true, type: isExtra[0].type, name: isExtra[0].fieldName };

  return extra;
};

export const useExtraField2 = (state: RootState) => {
  const isExtra: Array<{
    [name: string]: any;
  }> = state.user.extraFieldsArray.filter(
    (field: any) => field.parameter.toUpperCase() === "PAP2"
  );
  let extra = { isUsed: false, type: "", name: "" };

  if (isExtra.length > 0)
    extra = { isUsed: true, type: isExtra[0].type, name: isExtra[0].fieldName };

  return extra;
};

export const useExtraField3 = (state: RootState) => {
  const isExtra: Array<{
    [name: string]: any;
  }> = state.user.extraFieldsArray.filter(
    (field: any) => field.parameter.toUpperCase() === "PAP3"
  );
  let extra = { isUsed: false, type: "", name: "" };

  if (isExtra.length > 0)
    extra = { isUsed: true, type: isExtra[0].type, name: isExtra[0].fieldName };

  return extra;
};

export const useExtraField4 = (state: RootState) => {
  const isExtra: Array<{
    [name: string]: any;
  }> = state.user.extraFieldsArray.filter(
    (field: any) => field.parameter.toUpperCase() === "PAP4"
  );
  let extra = { isUsed: false, type: "", name: "" };

  if (isExtra.length > 0)
    extra = { isUsed: true, type: isExtra[0].type, name: isExtra[0].fieldName };

  return extra;
};

export const useExtraField5 = (state: RootState) => {
  const isExtra: Array<{
    [name: string]: any;
  }> = state.user.extraFieldsArray.filter(
    (field: any) => field.parameter.toUpperCase() === "PAP5"
  );
  let extra = { isUsed: false, type: "", name: "" };

  if (isExtra.length > 0)
    extra = { isUsed: true, type: isExtra[0].type, name: isExtra[0].fieldName };
  return extra;
};

export const allowEditProductQty = (state: RootState) => {
  const isParam: Array<{
    [name: string]: any;
  }> = state.user.extraFieldsArray.filter(
    (field: any) => field.parameter.toUpperCase() === "ALLOWEDITPRODUCTQTY"
  );
  let allowEditQty = false;

  if (isParam.length > 0)
    allowEditQty = isParam[0].code && isParam[0].code === ".T.";

  return allowEditQty;
};

export const allowRecalculateItems = (state: RootState) => {
  const isParam: Array<{
    [name: string]: any;
  }> = state.user.extraFieldsArray.filter(
    (field: any) => field.parameter.toUpperCase() === "ALLOWRECALCULATEITEMS"
  );
  let allowEditQty = false;

  if (isParam.length > 0)
    allowEditQty = isParam[0].code && isParam[0].code === ".T.";

  return allowEditQty;
};

export const userCamera = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateCamera = state.user.useCamera;
  const storage = localStorage.getItem("useCamera");
  let camera = false;

  if (stateCamera) camera = stateCamera;
  if (!stateCamera && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    camera =
      userInfo.length > 0 && userInfo[0].isUsed ? userInfo[0].isUsed : false;
  }
  return camera;
};

export const userCameraId = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateCameraId = state.user.cameraId;
  const storage = localStorage.getItem("cameraId");
  let cameraId = "";

  if (stateCameraId) cameraId = stateCameraId;
  if (!stateCameraId && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    cameraId =
      userInfo.length > 0 && userInfo[0].cameraId ? userInfo[0].cameraId : "";
  }
  return cameraId;
};

export const userDepartmentId = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const lastDb = localStorage.getItem("userDb");
  const stateUser = state.user.user?.userId;
  const stateDb = state.user.user?.clientDb ? state.user.user.clientDb.id : "";
  let user: string | undefined;
  let db: string | number | undefined;

  if (stateUser) user = stateUser;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  if (stateDb) db = stateDb;

  if (!db && lastDb && Array.isArray(JSON.parse(lastDb))) {
    const userDb = JSON.parse(lastDb).find(
      (index: any) => index.userId === user
    );
    if (userDb) db = parseInt(userDb.id);
  }

  const stateDepartmentId = state.user.departmentId;
  const storage = localStorage.getItem("departmentId");
  let departmentId = "";

  if (stateDepartmentId) departmentId = stateDepartmentId;
  if (!stateDepartmentId && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter(
          (person: any) => person.userId === user && person.dbId === db
        )
      : [];

    departmentId =
      userInfo.length > 0 && userInfo[0].departmentId
        ? userInfo[0].departmentId
        : "";
  }
  return departmentId;
};

export const userSeparator = (state: RootState) =>
  state.user.barcodeSeparator || ";";

export const userDeleteRow = (state: RootState) =>
  state.user.deleteRow || false;

export const userSearchUsage = (state: RootState) => {
  const lastUser = localStorage.getItem("lastUser");
  const stateDb = state.user.user?.userId;
  let user: string | undefined;

  if (stateDb) user = stateDb;
  if (!user && lastUser !== null) user = JSON.parse(lastUser).userId;

  const stateSearch = state.user.useSearch;
  const storage = localStorage.getItem("useSearch");
  let search = false;

  if (stateSearch) search = stateSearch;
  if (!stateSearch && storage !== null) {
    const userInfo = Array.isArray(JSON.parse(storage))
      ? JSON.parse(storage).filter((person: any) => person.userId === user)
      : [];

    search =
      userInfo.length > 0 && userInfo[0].isUsed ? userInfo[0].isUsed : false;
  }

  return search;
};
