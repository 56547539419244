import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "../components";
import { Assembly, AssemblyDetails } from "../modules/assembly";
import Auth from "../modules/auth";
import { isLoggedIn } from "../modules/auth/selectors/userSelector";
import Completion from "../modules/completion";
import {
  InternalMovements,
  InternalMovementsDetails,
} from "../modules/internalMovements";
import { Inventory, InventoryDetails } from "../modules/inventory";
import Options from "../modules/options";
import { ProductInfo } from "../modules/productInfo";
import { PurchaseDetails, Purchases } from "../modules/purchases";
import { Sales, SalesDetails } from "../modules/sales";
import { Scanner, ScannerDetails } from "../modules/scanner";
import Settings from "../modules/settings";
import { getTokenExpirationDate } from "../utils/authToken";
import * as routes from "./routes";

export default function AppRoutes() {
  const expCookie = getTokenExpirationDate();
  const isUserLoggedIn = useSelector(isLoggedIn) && !!expCookie;

  const loginPath = routes.ROUTE_LOGIN.path;

  return (
    <Switch>
      <PrivateRoute
        {...routes.ROUTE_LOGIN}
        isAllowed={!isUserLoggedIn}
        redirectTo={routes.ROUTE_OPTIONS.path}
        component={Auth}
      />
      <PrivateRoute
        {...routes.ROUTE_OPTIONS}
        component={Options}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_SETTINGS}
        component={Settings}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_SALES_DETAILS}
        path={routes.ROUTE_SALES_DETAILS.path + "/:id"}
        component={SalesDetails}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_SALES}
        component={Sales}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_PURCHASES_DETAILS}
        path={routes.ROUTE_PURCHASES_DETAILS.path + "/:id"}
        component={PurchaseDetails}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_PURCHASES}
        component={Purchases}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_INTERNAL_DETAILS}
        path={routes.ROUTE_INTERNAL_DETAILS.path + "/:id"}
        component={InternalMovementsDetails}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_INTERNAL}
        component={InternalMovements}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_SCANNER_DETAILS}
        path={routes.ROUTE_SCANNER_DETAILS.path + "/:id"}
        component={ScannerDetails}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_SCANNER}
        component={Scanner}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_INVENTORY_DETAILS}
        path={routes.ROUTE_INVENTORY_DETAILS.path + "/:id"}
        component={InventoryDetails}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_INVENTORY}
        component={Inventory}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_COMPLETION}
        path={routes.ROUTE_COMPLETION.path + "/:module/:id"}
        component={Completion}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_PRODUCT_INFO}
        component={ProductInfo}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />

      <PrivateRoute
        {...routes.ROUTE_ASSEMBLY_DETAILS}
        path={routes.ROUTE_ASSEMBLY_DETAILS.path + "/:id"}
        component={AssemblyDetails}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        {...routes.ROUTE_ASSEMBLY}
        component={Assembly}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />

      <Redirect
        to={{
          pathname: routes.ROUTE_LOGIN.path,
          state: {
            redirect: true,
          },
        }}
      />
    </Switch>
  );
}
