import React, { useCallback, useState, useEffect } from "react";
import { Dialog, Alert } from "../../../components";
import {
  createSalesPurchasesOperation,
  getSalesPurchasesParams,
} from "../../api/salesPurchases";
import { uiLanguage, userClientDb } from "../../auth/selectors/userSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getErrorMessage,
  getWarningMessage,
} from "../../../components/form/formUtils";
import { Redirect } from "react-router-dom";
import * as routes from "../../../app/routes";
import { Grid } from "@mui/material";
import { Form } from "react-final-form";
import {
  OutlinedSelectField,
  OutlinedTextField,
  OutlinedAutocomplete,
  WhenFieldChanges,
  ListboxComponent,
} from "../../../components/form";
import { required } from "../../../utils/validate";
import { useLoader, useClientsFetching } from "../../../hooks";

interface IPurchaseCreate {
  open: boolean;
  onClose: (event?: Record<string, unknown>) => void;
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

let id = "";

function PurchaseCreate(props: IPurchaseCreate) {
  const { open, onClose } = props;
  const [descriptionOptions, setDescriptionOptions] =
    useState<Array<{ value: string; label: string }>>();
  const [documentTypeOptions, setDocumentTypeOptions] =
    useState<Array<{ value: string; label: string }>>();
  const [redirect, setRedirect] = useState({ redirect: false, id: "" });
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    error: false,
  });
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const [, setLoader] = useLoader();

  const params = {
    clientDbId: userDb ? userDb.id : 0,
    locale: locale,
    module: "PO",
  };

  const [fil, setFil] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const { loading, error, clients, hasMore } = useClientsFetching(
    fil,
    pageNumber,
    "PO"
  );

  useEffect(() => {
    if (error) {
      const message = getErrorMessage(error);
      setShowAlert({ show: true, message: message.message, error: true });
    }
  }, [error]);

  const documentTypes = [
    { value: "1", label: translate("invoice", "Važtaraštis") },
    { value: "2", label: translate("return", "Grąžinimas") },
    { value: "3", label: translate("order", "Užsakymas") },
    { value: "4", label: translate("offer", "Pasiūlymas") },
  ];

  const initialValues = React.useMemo(
    () => ({
      documentType:
        documentTypeOptions && documentTypeOptions[0]
          ? documentTypeOptions[0].value
          : "",
      client: "",
      descriptionCode1: "",
    }),
    [documentTypeOptions]
  );

  React.useEffect(
    () => {
      setLoader(true);
      getSalesPurchasesParams(params)
        .then(({ data }) => {
          setLoader(false);
          if (data && data.length > 0) {
            const opArray2 = data
              .filter(
                (operation: any) =>
                  operation.parameter.toLowerCase() === "description"
              )
              .map((operation: any) => ({
                label: operation.code,
                value:
                  typeof operation.code !== "string"
                    ? JSON.stringify(operation.code)
                    : operation.code,
              }));

            opArray2.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDescriptionOptions(opArray2);

            // Documents types list
            const opArray3 = data.filter(
              (operation: any) =>
                operation.parameter.toLowerCase() === "doctypes"
            );

            const docArray = documentTypes.filter((type: any) => {
              return opArray3.some((el: any) => {
                return (
                  (typeof el.code !== "string"
                    ? JSON.stringify(el.code)
                    : el.code) === type.value
                );
              });
            });

            docArray.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDocumentTypeOptions(docArray);
          }
        })
        .catch((reason) => {
          setLoader(false);
          const message = getErrorMessage(reason);
          setShowAlert({ show: true, message: message.message, error: true });
        });
    }, // eslint-disable-next-line
    []
  );

  const handleClientSearch = (value: string) => {
    setFil(
      `clientName like '%${value || ""}%' or companyCode like '%${
        value || ""
      }%' or clientCode like '%${value || ""}' `
    );

    setPageNumber(1);
  };

  const handleErrorClose = () => {
    setShowAlert({ show: false, message: "", error: false });
    onClose();
  };

  const handleSubmit = (values: any) => {
    const opParams = {
      ...params,
      clientCode: values.client,
      description: values.description,
      documentType: values.documentType,
    };

    createSalesPurchasesOperation(opParams)
      .then(({ data, warnings }) => {
        if (warnings && warnings.length > 0) {
          id = warnings[0].operationCode;
          const message = getWarningMessage(warnings);
          setShowAlert({ show: true, message: message, error: false });
          return;
        }

        id = data[0].operationCode;
        setRedirect({ redirect: true, id: id });
      })
      .catch((reason) => {
        const message = getErrorMessage(reason);
        setShowAlert({ show: true, message: message.message, error: true });
      });
  };

  const handleWarningClose = useCallback(() => {
    setRedirect({ redirect: true, id: id });
  }, [setRedirect, id]);

  const showError = (
    <Alert
      isOpen={showAlert.show}
      message={showAlert.message}
      onClose={showAlert.error ? handleErrorClose : handleWarningClose}
      iconColor={showAlert.error ? "error" : "warning"}
    />
  );

  const loadNextPage = React.useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber, setPageNumber]);

  const clientsOptions = React.useMemo(() => {
    return [
      ...clients.map((client) => {
        return {
          label:
            client.companyCode +
            (client.companyCode === "" ? "" : ": ") +
            client.clientName,
          value:
            typeof client.clientCode !== "string"
              ? JSON.stringify(client.clientCode)
              : client.clientCode,
        };
      }),
    ];
  }, [clients]);

  return (
    <div>
      {showAlert.show && showError}
      {redirect.redirect ? (
        <Redirect
          to={routes.ROUTE_PURCHASES_DETAILS.path + "/" + redirect.id}
        />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          subscription={subscription}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} method="post">
              <Dialog
                open={open}
                onClose={onClose}
                title={translate("createOperation", "Nauja operacija")}
                isConfirmationButton
                onConfirm={handleSubmit}
              >
                <Grid container spacing={1} direction="column">
                  <Grid item xs={12}>
                    <OutlinedSelectField
                      name="documentType"
                      fullWidth
                      label={translate("docType", "Dokumento tipas")}
                      options={documentTypeOptions}
                      otherColor="grey"
                      validate={required}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OutlinedAutocomplete
                      name="client"
                      fullWidth
                      label={translate("client", "Klientas")}
                      loading={loading}
                      options={clientsOptions}
                      otherColor="grey"
                      validate={required}
                      required
                      renderType="renderExtra"
                      ListboxComponent={
                        ListboxComponent as React.ComponentType<
                          React.HTMLAttributes<HTMLElement>
                        >
                      }
                      ListboxProps={{
                        hasNextPage: hasMore,
                        isNextPageLoading: loading,
                        loadNextPage: loadNextPage,
                      }}
                    />

                    <WhenFieldChanges
                      field="client"
                      onChange={handleClientSearch}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {descriptionOptions && descriptionOptions.length > 0 ? (
                      <OutlinedAutocomplete
                        name="description"
                        fullWidth
                        label={translate("remarks", "Pastabos")}
                        options={descriptionOptions}
                        otherColor="grey"
                        renderType="renderSimple"
                      />
                    ) : (
                      <OutlinedTextField
                        name="description"
                        fullWidth
                        label={translate("remarks", "Pastabos")}
                        otherColor="grey"
                        autoComplete="off"
                        maxLength={60}
                      />
                    )}
                  </Grid>
                </Grid>
              </Dialog>
            </form>
          )}
        />
      )}
    </div>
  );
}

export default PurchaseCreate;
