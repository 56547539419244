import React, { useState } from "react";
import { InfoOutlined, CreateOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Drawer from "./Drawer";
import { moduleType, MODULE_ASSEMBLY } from "../utils/modules";
import { AssemblyEditQuantityForm } from "../modules/assembly";
import {
  allowEditProductQty,
  allowRecalculateItems,
} from "../modules/auth/selectors/userSelector";
import { useSelector } from "react-redux";
import Menu from "./Menu";

interface IHeaderMenu {
  open: boolean;
  onClose: () => void;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  data: { [key: string]: any };
  module?: moduleType;
}

function HeaderMenu(props: IHeaderMenu) {
  const { open, onClose, anchorEl, data, module } = props;
  const [translate] = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [info, setInfo] = useState({});
  const [openQtyAdj, setOpenQtyAdj] = useState(false);
  const allowEditQty = useSelector(allowEditProductQty);
  const allowRecalculate = useSelector(allowRecalculateItems);

  const initialValuesAssembly = React.useMemo(() => {
    if (module !== MODULE_ASSEMBLY)
      return { productQuantity: 0, editComponents: false, operationCode: "" };

    let qty = data.productQuantity ? data.productQuantity : 0;
    qty = typeof qty === "string" ? JSON.parse(qty) : qty;

    return {
      productQuantity: qty,
      editComponents: allowRecalculate,
      operationCode: data.operationCode || "",
    };
  }, [data, allowRecalculate, module]);

  const handleDocInfo = () => {
    const newData: { [name: string]: any } = { ...data };
    delete newData.element1;
    delete newData.element2;
    delete newData.numberOfElements;
    delete newData.size;
    delete newData.pageNumber;
    delete newData.Row_number;
    delete newData.totalElements;

    setOpenDrawer(true);
    setInfo(newData);
  };

  const handleProductQty = () => setOpenQtyAdj(true);

  const menuItems = React.useMemo(
    () => {
      const list = [
        {
          name: translate("documentInformation"),
          icon: <InfoOutlined />,
          onClick: handleDocInfo,
        },
      ];

      if (module === MODULE_ASSEMBLY && allowEditQty)
        list.push({
          name: translate("productAmountExchange"),
          icon: <CreateOutlined />,
          onClick: handleProductQty,
        });

      return list;
    }, // eslint-disable-next-line
    [module]
  );

  const handleCloseDrawer = () => {
    onClose();
    setOpenDrawer(false);
  };

  const handleCloseQtyAdj = () => {
    onClose();
    setOpenQtyAdj(false);
  };

  return (
    <div>
      {openDrawer && (
        <Drawer isOpen={openDrawer} onClose={handleCloseDrawer} data={info} />
      )}

      {openQtyAdj && (
        <AssemblyEditQuantityForm
          open={openQtyAdj}
          onClose={handleCloseQtyAdj}
          initialValues={initialValuesAssembly}
        />
      )}

      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        data={menuItems}
      />
    </div>
  );
}

export default HeaderMenu;
